//style
import '@/common/css/resect.css'
import '@/common/css/element-variables.scss'
//jy 样式
import '@/common/css/jy.dialog.scss'
import '@/common/css/jy.search.scss'

import '@/common/js/config.js'
import '@/common/js/prototype.js'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'

import Util from '@/common/js/util.js'
Vue.prototype.$util = Util;

import Http from '@/common/js/http.js'
Vue.prototype.$http = Http;

Vue.config.productionTip = false

//jyui
import jyui from '@/jyui/index'
const option = {
	global: {
		dialogTreeWidth: '380px',
		dialogMapWidth: '80%',
		isDev: process.env.NODE_ENV !== 'production'
	}
};
Vue.use(jyui, option)

// mixin
import Mixin from '@/common/js/vue.mixin.js'
Vue.mixin(Mixin)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
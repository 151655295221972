Date.prototype.Format = function(fmt) {
    var o = {
        'M+': this.getMonth() + 1, //月份   
        'd+': this.getDate(), //日   
        'h+': this.getHours(), //小时   
        'm+': this.getMinutes(), //分   
        's+': this.getSeconds(), //秒   
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度   
        'S': this.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
    }
    return fmt;
};
Date.prototype.DateByNum = function(n, fmt) {
    var s, d, t, t2;
    t = this.getTime();
    t2 = n * 1000 * 3600 * 24;
    t += t2;
    d = new Date(t);
    s = d.Format(fmt);
    return s;
};
Array.prototype.contains = function(val){
    for(let key in this){
        if (this[key] === val) {
            return true
        }
    }
    return false
}

//随机数
Math.stochastic = function(star, end) {
    var c = end - star + 1;
    return Math.random() * c + star;
}
